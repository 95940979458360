import { NextRouter } from 'next/router';

/**
 * Converts a given string to lowercase and replaces spaces with dashes.
 *
 * @param {string} string - The string to convert.
 * @returns {string} - The string in lowercase with spaces replaced by dashes.
 */
function getSlug(string) {
    return string.toLowerCase().replace(/ /g, '-');
}
/**
 * Capitalizes the first letter of a given string.
 *
 * @param {string} string - The string to capitalize.
 * @returns {string} - The string with the first letter capitalized.
 */
function getCapitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).replace(/-/g, ' ');
}

const getCanonicalUrl = (router: NextRouter) => {
    if (router.pathname.includes('[tag]')) {
        return `${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn/tag/${router.query.tag}${
            router.query.page ? `?page=${router.query.page}` : ''
        }`;
    }
    if (router.pathname.includes('[cid]')) {
        return `${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn/categories/${router.query.cid}${
            router.query.page ? `?page=${router.query.page}` : ''
        }`;
    }
    if (router.pathname.includes('[deal]')) {
        return `${process.env.NEXT_PUBLIC_APP_DOMAIN}/porn/deals/${router.query.deal}${
            router.query.page ? `?page=${router.query.page}` : ''
        }`;
    }
};

export { getCanonicalUrl, getCapitalize, getSlug };
