import Card from '@components/partials/common/card/card';
import styles from '@styles/default-page-scss/list-cards/_list.module.scss';
import React, { useRef } from 'react';

import { CardDataArrayType, CardDataType } from '../../../lib/types/cardProps';
import { holidayProps } from '../../../lib/types/holidayProps';

interface Types {
    holiday: holidayProps;
    cards?: CardDataArrayType;
    videocards?: any;
    tag: string;
    customHTML?: { description?: boolean; container?: string };
    cardType?: string;
}

/**
 * Pagination Component
 *
 * This component is responsible for rendering a list of cards with optional padding on top of the first two cards
 * if they contain specific labels. It is typically used for paginated card listings with optional filtering.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.holiday - The current holiday or event name.
 * @param {Array} props.cards - An array of card data objects to render.
 * @param {string} props.tag - The page tag associated with the cards fot GTM Tag Manager
 * @returns {JSX.Element} - The JSX element representing the FlexPagination component.
 */
export default function FlexPagination({ holiday, cards, tag, customHTML }: Types): JSX.Element {
    const listRef = useRef([]);

    /**
     * Check if specific labels exist on a card item. Add padding top to all the cards if the first 2 cards have any labels fot styles.allCards So that they don't overlap with the filters
     *
     * @param {Object} item - The card data object.
     * @returns {boolean} - True if the card has specific labels, otherwise false.
     */

    return (
        <section className={`${styles.listSection}`}>
            <div className={styles.allCards}>
                <div className={styles.cardList}>
                    {cards &&
                        cards.map((card: CardDataType, index: number) => (
                            <div className={styles.cardDiv} key={index} ref={(el) => (listRef.current[index] = el)}>
                                <Card site={card} holiday={holiday} pageTag={tag} index={index} customHTML={customHTML} />
                            </div>
                        ))}
                </div>
            </div>
        </section>
    );
}
