import styles from '@styles/common-scss/_introText.module.scss';
import { useRouter } from 'next/router';

//styles for this is at snippets/review/_introSnippet.scss
interface ISplitIntroTextProps {
    introTitle?: string;
    introContent: string;
}

const SplitIntroText: React.FC<ISplitIntroTextProps> = ({ introContent, introTitle = '' }) => {
    const router = useRouter();
    return (
        <div className={styles.splitIntroSection}>
            {introTitle && <h1 className={styles.introTitle}>{introTitle}</h1>}
            {/* if youre on a paginated page,say page=2, then dont show the Intro Box */}
            {introContent && !router.query.page && (
                <section className={styles.introContainer}>
                    <div
                        id="basic-collapsible"
                        className={`${styles.marginTop} ${styles.introContent} ${styles.isOpen}`}
                        dangerouslySetInnerHTML={{ __html: introContent }}
                    />
                </section>
            )}
        </div>
    );
};

export default SplitIntroText;
