import { useEffect, useState } from 'react';

export const useCrosslinkParse = (
    snippetData: { error: string } & { id: number; slug: string; text: string },
    pageName: string
) => {
    const [crosslinkData, setCrosslinkData] = useState<{ title: string; links: { name: string; link: string }[] }>({
        title: `Related Categories to ${pageName} Porn Sites`,
        links: []
    });

    useEffect(() => {
        if (!snippetData || snippetData.error || !pageName) {
            return;
        }

        const crosslinkDefault = {
            title: `Related Categories to ${pageName} Porn Sites`,
            links: []
        };

        const lines = String(snippetData.text).split('\n'); // Split the input string into lines
        const result = { Before: [], After: [], links: [] };

        lines.forEach((line, index) => {
            line = line.replace(/["\r]/g, '');
            const parts = line.split('\t'); // Split each line into tokens using tab spaces
            if (index === 0 || index === 1) {
                const key = parts[0].trim();
                const value = parts[1].trim();
                //split the righthand side by comma to retrieve all the before and after words
                const splitValue = value.split(',');
                //insert into result.before and result.after object
                result[key] = [...splitValue];
            } else {
                const type = parts[0].trim();
                const page = parts[1].trim();
                //the rest of the text will be pushed as a type,page object
                if (result['links']) {
                    result['links'].push({ type: type, page: page });
                } else {
                    result['links'] = [{ type: type, page: page }];
                }
            }
        });
        //then go through each link and turn them into the way carousel component uses it
        if (result && result?.links && result.links.length > 0) {
            result.links.map((item) => {
                const pageType = item.type.trim() === 'Category' ? 'categories' : item.type.toLowerCase().trim();
                crosslinkDefault.links.push({
                    //for the name assign a random Before word + the page name + a random After word
                    name: `${result?.Before[Math.floor(Math.random() * result?.Before.length)]} ${item.page.trim()} ${result?.After[Math.floor(Math.random() * result?.After.length)]}`,
                    link: `/porn/${pageType}/${item.page.toLowerCase().trim().split(' ').join('-')}`
                });
            });
        }
        setCrosslinkData(crosslinkDefault);
    }, [snippetData, pageName]); // Added snippetData and pageName as dependencies to useEffect

    return crosslinkData;
};
