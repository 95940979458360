import styles from '@styles/list-page-scss/_sortOptions.module.scss';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function SortTabs(): JSX.Element {
    const router = useRouter();
    const [tab, setTab] = useState(findTab());
    useEffect(() => {
        setTab(findTab());
    }, [router]);

    function findTab() {
        if (router.query && router.query.sort) {
            switch (router.query.sort) {
                case 'new':
                    return 2;
                case 'score':
                    return 1;
                default:
                    return 0;
            }
        } else {
            return 0;
        }
    }
    function changeSort(name: string) {
        const parsedUrl = new URL(process.env.NEXT_PUBLIC_APP_DOMAIN + router.asPath);

        if (parsedUrl.searchParams.has('page')) {
            // The 'page' query parameter exists in the URL
            // Delete its value
            parsedUrl.searchParams.delete('page');
        }
        if (name === 'relevance') {
            // The 'sort' query parameter is unnecessary
            // since the default sorting is based on relevance.
            // So we delete it.
            parsedUrl.searchParams.delete('sort');
        } else {
            if (parsedUrl.searchParams.has('sort')) {
                // The 'sort' query parameter exists in the URL
                // Modify its value
                parsedUrl.searchParams.set('sort', name);
            } else {
                // The 'sort' query parameter does not exist in the URL
                // Add it to the URL
                parsedUrl.searchParams.append('sort', name);
            }
        }
        return parsedUrl.toString();
    }

    return (
        <div className={styles.tabContainer}>
            <div className={styles.tabLinks}>
                <a
                    className={`${styles.tab} ${styles.firstTab} ${tab === 0 ? styles.chosenTab : ''}`}
                    href={changeSort('relevance')}>
                    RELEVANCE
                </a>
                <a className={`${styles.tab} ${tab === 1 ? styles.chosenTab : ''}`} href={changeSort('score')}>
                    RABBITS SCORE
                </a>
                <a className={`${styles.tab} ${styles.lastTab} ${tab === 2 ? styles.chosenTab : ''}`} href={changeSort('new')}>
                    NEWEST
                </a>
            </div>
        </div>
    );
}
