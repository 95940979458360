export function findNextPageSlug(router) {
    //if next page is the last page, then we won't add the next rel link
    const nextPage = new URL(process.env.NEXT_PUBLIC_APP_DOMAIN + router.asPath);
    //If we're on the first page, we need to specify the page as 1
    const currentPage = router.query.page ? Number(router.query.page) : 1;
    nextPage.searchParams.set('page', String(currentPage + 1));
    return String(nextPage.href);
}
export function findPrevPageSlug(router) {
    //if page doesnt exist as a page query, meaning it's the first page,
    //then we won't add the prev rel link
    const prevPage = new URL(process.env.NEXT_PUBLIC_APP_DOMAIN + router.asPath);
    //if prev is the first page, then we delete the page page query altogether
    if (router.query.page === '2') {
        prevPage.searchParams.delete('page');
    }
    //otherwise we just decrement the page by one
    else {
        prevPage.searchParams.set('page', String(Number(router.query.page) - 1));
    }
    return String(prevPage);
}
