import ChevronLeft from '@components/svgs/svgChevronLeft';
import ChevronRight from '@components/svgs/svgChevronRight';
import styles from '@styles/common-scss/_pagination.module.scss';
import { useRouter } from 'next/router';
import React from 'react';

import { paginationLimit } from '../../../../lib/web/config';
type Props = {
    numberOfSites: number;
    limit?: number;
};

export default function Pagination({ numberOfSites, limit }: Props): JSX.Element {
    const effectivePaginationLimit = limit !== undefined ? limit : paginationLimit;

    const router = useRouter();
    //taking in all the URL details now, to attach it to the Next Links later on
    const path = router.asPath;
    const currentPage = router.query.page ? Number(router.query.page) : 1;
    const lastPage = numberOfSites && Number(numberOfSites) > 0 ? Math.ceil(Number(numberOfSites / effectivePaginationLimit)) : 1;

    const findRange = (start, end) => {
        const range = end - start + 1;
        return Array.from({ length: range }, (num, index) => (index += start));
    };
    const paginationStructure = () => {
        let finalStructure = [];
        if (lastPage < 10) {
            const middleRange = findRange(1, lastPage);
            finalStructure = [...middleRange];
        }
        // if there's less than 3 siblings on the left
        else if (currentPage - 3 <= 2) {
            const middleRange = findRange(1, currentPage + 2 + Math.abs(currentPage - 5));

            finalStructure = [...middleRange];
            //add 25% if it's bigger than 7
            Math.ceil((lastPage - currentPage) / 4) > 7 ? finalStructure.push(Math.ceil((lastPage - currentPage) / 4)) : '';
            //add 50% if it's bigger than 7
            Math.ceil((lastPage - currentPage) / 2) > 7 ? finalStructure.push(Math.ceil((lastPage - currentPage) / 2)) : '';
            //add 75% if it's bigger than 7
            Math.ceil(((lastPage - currentPage) * 3) / 4) > 7
                ? finalStructure.push(Math.ceil(((lastPage - currentPage) * 3) / 4))
                : '';

            finalStructure.push(lastPage);
        }
        // if there's less than 3 siblings on the right
        else if (currentPage + 3 >= lastPage - 1) {
            const middleRange = findRange(currentPage - 2 - Math.abs(lastPage - currentPage - 4), lastPage - 1);
            finalStructure.push(1);
            finalStructure.push(Math.ceil(currentPage / 4));
            finalStructure.push(Math.ceil(currentPage / 2));
            Math.ceil((currentPage * 3) / 4) < currentPage - 7 ? finalStructure.push(Math.ceil((currentPage * 3) / 4)) : '';
            finalStructure.push(...middleRange);
            finalStructure.push(lastPage);
        }
        // if there's 3 siblings to the left, and 3 siblings to the right
        // without touching the edges
        else if (currentPage - 3 > 2 && currentPage + 3 < lastPage) {
            let middleRangeLeft = [];
            let middleRangeRight = [];
            const leftSide = [];
            const rightSide = [];
            //if we're not too close to the last page, then show 25%,50%,75% and last page
            if (Math.ceil((lastPage - currentPage) / 4) > 2) {
                rightSide.push(
                    currentPage + Math.ceil((lastPage - currentPage) / 4),
                    currentPage + Math.ceil((lastPage - currentPage) / 2),
                    currentPage + Math.ceil(((lastPage - currentPage) * 3) / 4)
                );
                middleRangeRight = findRange(currentPage + 1, currentPage + 2);
            }
            //if we're almost at the last page, then just simply put current page till current Page +4
            else {
                middleRangeRight = findRange(currentPage + 1, currentPage + 4);
            }
            //if the 25% of the current page is bigger than 2, then put 25%,50%,75% to the left side
            if (Math.ceil(currentPage / 4) > 2) {
                leftSide.push(Math.ceil(currentPage / 4), Math.ceil(currentPage / 2));
                middleRangeLeft = findRange(currentPage - 2, currentPage);

                //the 75% of the left side sometimes is the same number or higher than the
                // currentPage -2, so we need to make sure that's not the case here before we push the 75%
                if (currentPage - 2 > Math.ceil((currentPage * 3) / 4)) {
                    leftSide.push(Math.ceil((currentPage * 3) / 4));
                }
            }
            //if the 25% of the current page is lower than 2, then put 4 pages on the left side
            else {
                middleRangeLeft = findRange(currentPage - 4, currentPage);
            }
            finalStructure = [1, ...leftSide, ...middleRangeLeft, ...middleRangeRight, ...rightSide, lastPage];
        }
        return [...finalStructure];
    };
    const prepareLink = (pageNumber: number) => {
        const parsedUrl = new URL(process.env.NEXT_PUBLIC_APP_DOMAIN + path);
        pageNumber === 1 ? parsedUrl.searchParams.delete('page') : parsedUrl.searchParams.set('page', String(pageNumber));
        return parsedUrl.toString();
    };

    return (
        <div className={styles.paginationContainer}>
            <div className={lastPage > 6 ? `${styles.paginationFlexStart} ${styles.pagination}` : styles.pagination}>
                {currentPage - 1 && lastPage > 9 ? (
                    <div className={styles.paginationChevron}>
                        <a href={prepareLink(currentPage - 1)}>
                            <ChevronLeft />
                        </a>
                    </div>
                ) : (
                    ''
                )}
                {paginationStructure().map((item, index) =>
                    item === '...' ? (
                        <span className={styles.paginationDots} key={index}>
                            ...
                        </span>
                    ) : (
                        <div key={index} className={`${item === currentPage ? styles.curPage : ''} ${styles.paginationButton}`}>
                            <a href={prepareLink(Number(item))} className={styles.paginationNumber}>
                                {item}
                            </a>
                        </div>
                    )
                )}
                {currentPage === lastPage || lastPage < 9 ? (
                    ''
                ) : (
                    <div className={styles.paginationChevron}>
                        <a href={prepareLink(currentPage + 1)}>
                            <ChevronRight />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}
