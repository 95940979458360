/**
 * The Chevron Right icon
 * @returns SVG
 */

export default function ChevronRight(): JSX.Element {
    return (
        <>
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 13L7 7L1 1" stroke="#216600" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
}
